html {
  background-color: black;
  font-family: "Overlock", cursive;
  text-align: center;
  margin: auto;
  overflow-x: hidden;
  font-family: "Quicksand", sans-serif;
}
button {
  background: none;
  border: none;
  padding: 0;
}
.navbar {
  background-color: white;
  border-radius: 50px;
  height: 8vh;
  width: 8vh;
  margin: 10px 0 0 0;
}
.icon {
  margin-top: 3px;
}

.picture-img {
  max-width: 70%;
  border-radius: 30px;
  margin: auto;
}
.grid-img {
  max-width: 90%;
  margin: auto;
}

.title-no-grid {
  font-size: 18pt;
  margin-top: 70px;
  color: white;
  font-weight: 700;
  font-style: italic;
}
.title-with-grid {
  margin-top: 50px;
  color: white;
  font-weight: 500;
  font-size: 15pt;
}

.grid-icon {
  width: 40px;
  margin: 12px 0 0 0;
}
.grid-icon:hover {
  width: 41px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}
.grid-item {
  font-size: 30px;
  text-align: center;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;
}

.card-view {
  position: absolute;
  top: 1%;
  left: 1%;
  z-index: 100;
  border-radius: 0;
}

.card-view-img {
  max-width: 90%;
  margin: auto;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 120; /* Make sure it does not overlap */
  background-color: white;
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 20px;
  border-radius: 50%;
  font-size: 25px;
}
.opacity-btn {
  opacity: 0.3;
  transition: opacity 1s;
}
.opacity-zero {
  opacity: 1;
  transition: opacity 1s;
}

.hover-text-top {
  position: fixed;
  right: 17px;
  bottom: 90px;
  border-radius: 10px;
  background-color: gainsboro;
  cursor: pointer; /* Add a mouse pointer on hover */
  font-size: 12px;
  padding: 0 20px 0 20px;
  transition: 1s;
  z-index: 120; /* Make sure it does not overlap */
}

.full-size-box {
  margin-top: 2%;
  opacity: 0.8;
}
.full-size {
  background-color: gainsboro;
  border-radius: 20px;
  color: black;
  font-weight: 700;
  padding: 10px 20px;
  text-decoration: none;
}
.full-size:hover {
  background-color: #b39200;
  color: white;
  transition: color 0.5s;
  transition: background-color 0.5s;
  /* color: blue; */
}

@media (max-width: 800px) {
  body {
    overflow: hidden;
  }
  h1 {
    font-size: 15pt;
  }
  .title-no-grid {
    font-size: 15pt;
  }
  .title-with-grid {
    font-size: 12pt;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .picture-img {
    max-width: 80%;
  }
  .grid-img {
    max-width: 80%;
  }
  .grid-icon {
    width: 35px;
    margin: 15px 0 0 0;
  }
  .grid-icon:hover {
    width: 36px;
  }
  .card-view {
    top: 32%;
  }
  .hover-text-top {
    visibility: hidden;
  }
  .full-size-box {
    margin-top: 5%;
  }
  .full-size {
    font-size: 10pt;
  }
}
